import type { Database } from '~/types/database.types'

export default defineNuxtRouteMiddleware(async (to) => {
  const supabase = useSupabaseClient<Database>()

  const { data } = await supabase.auth.getUser()

  if (!data.user) {
    const cookies = useCookie('redirect')
    cookies.value = to.path
    return navigateTo({ name: 'login' })
  }

  const { data: profile } = await supabase
    .from('profiles')
    .select(
      `
      *,
      companies(
        is_supplier
      )
    `
    )
    .eq('id', data.user.id)
    .limit(1)
    .maybeSingle()

  if (
    to.name !== 'plattform-registrieren' &&
    (!profile?.first_name || !profile?.last_name)
  ) {
    return navigateTo({ name: 'plattform-registrieren' })
  }

  if (to.name === 'plattform-become-supplier') {
    if (profile?.companies?.is_supplier)
      return navigateTo({ name: 'plattform-marketplace' })
  }

  if (to.name === 'plattform-marketplace') {
    if (!profile?.companies?.is_supplier)
      return navigateTo({ name: 'plattform-requests' })
  }
})
